import React from 'react';

const Policy = () => (
  <div className="flex flex-col gap-2 items-center">
    <div className="w-[90%] md:w-[70%] mt-4">

      <h1 className=" text-[#ac4b00] font-bold text-2xl">Privacy Policy/Disclaimer</h1>

      <p className=" font-semibold font-mono">
        This Privacy Policy/Disclaimer governs the manner in which Namur Fuel Economizer collects,
        uses, maintains, and discloses information collected from users (each, a User) of the
        Namur Fuel Economizer website and related services.
      </p>

      <h2 className=" text-[#ac4b00] font-bold text-xl mt-3">Information We Collect</h2>

      <p className=" font-semibold font-mono">
        We may collect personal identification information from Users in various ways,
        including, but not limited to, when Users visit our site, place an order, subscribe to
        the newsletter, respond to a survey, fill out a form, and in connection
        with other activities,
        services, features, or resources we make available on our site.
      </p>

      <h2 className=" text-[#ac4b00] font-bold text-xl mt-3">How We Use Collected Information</h2>

      <p className=" font-semibold font-mono">
        Namur Fuel Economizer may collect and use Users personal
        informationfor the following purposes:
      </p>

      <ul>
        <li>To improve customer service</li>
        <li>To personalize user experience</li>
        <li>To process transactions</li>
        <li>To send periodic emails</li>
      </ul>

      <h2 className=" text-[#ac4b00] font-bold text-xl mt-3">Disclaimer</h2>

      <p className=" font-semibold font-mono">
        The Namur Fuel Economizer is designed to improve fuel efficiency and engine performance.
        While we strive to provide accurate and up-to-date information, the actual results may
        vary based on various factors, including vehicle condition, driving habits, and maintenance.
      </p>

      <p className=" font-semibold font-mono">
        Namur Fuel Economizer makes no warranties, expressed or implied,
        regarding the effectiveness or results of the product. Users are advised to
        consult with automotive professionals for personalized advice on
        fuel efficiency and engine maintenance.
      </p>

      <h2 className=" text-[#ac4b00] font-bold text-xl mt-3">Changes to this Privacy Policy/Disclaimer</h2>

      <p className=" font-semibold font-mono">
        Namur Fuel Economizer has the discretion to update this Privacy
        Policy/Disclaimer at any time. Users are encouraged to check this page
        for any changes. By using this site, you acknowledge and agree that it is your
        responsibility to review this Privacy Policy/Disclaimer periodically
        and become aware of modifications.
      </p>
    </div>
  </div>
);

export default Policy;
